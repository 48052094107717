function App() {
  const hostname_arr = window.location.hostname.split(".");
  const subdomain = hostname_arr.length > 2 ? hostname_arr[0] : "";

  if (subdomain !== "") {
    const result = subdomain.toUpperCase().replace(/[A-Z]/g, (m) => m.charCodeAt() - 65);
    window.location.replace(`https://${process.env[`REACT_APP_${hostname_arr[1]}_${hostname_arr[2]}`]}/${result}`);
  }
}

export default App;
